import React from "react"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
}

const PartnerSlider = () => (
  <section id="reseller-benefits" className="py-md-4 mb-4">
    <article className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="mb-4 h3 text-center font-weight-semibold">
            Many Institutions use Fuse Classroom with Tens of Thousands of Students Worldwide
          </div>
        </div>
        <div className="col-12">
          <div className="partner__name">
            <div className="partners--item">USA</div>
            <div className="partners--item red">NEPAL</div>
            <div className="partners--item teal">UK</div>
            <div className="partners--item yellow">SOUTH AFRICA</div>
          </div>

          <Carousel
            arrows={false}
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="all 800ms ease-in-out"
            transitionDuration={800}
            containerClass="carousel-container partner__slider"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {/*<div>
                <img src="/images/partners/USA.svg" width="auto" height="68"  alt="USA" />
            </div>*/}
            <div>
              <img src="/images/partners/us-columbia-university.svg" width="auto" height="68" alt="columbia" />
            </div>
            <div>
              <img src="/images/partners/us-northwestern-university.svg" width="auto" height="68" alt="northwestern" />
            </div>
            {/*<div>
               <img src="/images/partners/nepal.svg" width="auto" height="68"  alt="Nepal" />
               </div>*/}
            <div>
              <img src="/images/partners/np-prime.svg" width="auto" height="68" alt="Prime" />
            </div>
            <div>
              <img src="/images/partners/np-thames.svg" width="auto" height="68" alt="Thames" />
            </div>
            <div>
              <img src="/images/partners/np-samriddhi-school.svg" width="auto" height="68" alt="samridhi-school" />
            </div>
            <div>
              <img src="/images/partners/np-kist.svg" width="auto" height="68" alt="KIST" />
            </div>
            <div>
              <img src="/images/partners/np-swarnim.svg" width="auto" height="68" alt="Swarnim" />
            </div>
            <div>
              <img src="/images/partners/np-trinity.svg" width="auto" height="68" alt="Trinity" />
            </div>
            <div>
              <img src="/images/partners/np-hetauda.svg" width="auto" height="68" alt="HCC" />
            </div>
            <div>
              <img src="/images/partners/np-samriddhi.svg" width="auto" height="68" alt="samriddhi" />
            </div>
            {/*<div>
                <img src="/images/partners/uk.svg" width="auto" height="68"  alt="United Kingdom" />
            </div>*/}
            <div>
              <img src="/images/partners/uk-twc.svg" width="auto" height="68" alt="woolwich" />
            </div>
            {/*<div>
                <img src="/images/partners/sa.svg" alt="South Africa" />
            </div>*/}
            <div>
              <img src="/images/partners/sa-rlabs.svg" width="auto" height="68" alt="RLABS" />
            </div>
          </Carousel>
        </div>
      </div>
    </article>
  </section>
)

export default PartnerSlider
